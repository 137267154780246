import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppUrlConstants, LoginUrlConstants } from '../../shared/constants/url-constants';
import { Role, Permission } from '../../shared/models/roles.model';
import { AuthModel } from '../../login/models/login.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<AuthModel>;
    public currentUser: Observable<AuthModel>;
    public roles: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>([]);
    public permissions: BehaviorSubject<Permission[]> = new BehaviorSubject<Permission[]>([]);
    userClaims: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
        
    constructor(private http: HttpClient) {
        let user = JSON.parse(localStorage.getItem('currentUser'));
        this.currentUserSubject = new BehaviorSubject<AuthModel>(user);
        this.currentUser = this.currentUserSubject.asObservable();
        if (user) {
            this.decodeToken(user.token);
        }
    }

    public get currentUserValue(): AuthModel {
        return this.currentUserSubject.value;
    }

    async getRoles(force?: boolean) { 
        if (this.roles.value.length == 0 || force) {
            let result = await this.http.get<Role[]>(`${AppUrlConstants.GET_ROLES_URL}`).toPromise();
            this.roles.next(result);
        }
        return this.roles.value;
    }

    async getApplicationPermissions(force?: boolean) {      
        if (this.permissions.value.length == 0 || force) {
            let result = await this.http.get<Permission[]>(`${AppUrlConstants.GET_PERMISSIONS_URL}`).toPromise();
            this.permissions.next(result);
        }
        return this.permissions.value;
    }

    isInRole(roles: string[]) {    
        return this.userClaims.value.find(x => roles.includes(x)) ? true : false;
    }

    login(username: string, password: string) {        
        return this.http.post<AuthModel>(`${LoginUrlConstants.LOGIN_URL}`, { username, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                // this.currentUserSubject.next(user);
                this.decodeToken(user.token);
                return user;
            }));
    }

    private decodeToken(token: string) {        
        let tokenData = JSON.parse(atob(token.split('.')[1]));      
        this.setClaims(tokenData.role);
    }

    private setClaims(roleClaims: any) {       
        if (typeof roleClaims == 'string') {
            this.userClaims.next([roleClaims]);
        }
        else if (typeof roleClaims == 'object') {
            this.userClaims.next(roleClaims);
        }
    }

    setUserContext(user: AuthModel) {       
        this.currentUserSubject.next(user);
        
        localStorage.setItem('currentUser', JSON.stringify(user));

        if(localStorage.getItem('currentDate') == undefined){

            localStorage.setItem('currentDate', new Date().toLocaleDateString());

        }
       
    }

    changePassword(currentPassword: string, newPassword: string): Observable<boolean> {
        return this.http.put<boolean>(`${LoginUrlConstants.CHANGE_PASSWORD_URL}`, { currentPassword, newPassword});
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        // localStorage.clear();
        localStorage.setItem('completedMatch', '[]');
        this.currentUserSubject.next(null);
    }
}
