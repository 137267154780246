import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { HomeComponent } from './home/home.component';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';
import { LogoutComponent } from './shared/components/logout/logout.component';

const appRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: '',
    loadChildren: () =>
      import('./matchhub/matchhub.module').then(
        (m) => m.MatchhubModule
      ),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      routePermissionCode: 'MatchHubPWAModule',
      },
  },
  {
    path: 'matchhub',
    loadChildren: () =>
      import('./matchhub/matchhub.module').then(
        (m) => m.MatchhubModule
      ),
    canActivate: [AuthGuard, RoleGuard],
    data: {
      routePermissionCode: 'MatchHubPWAModule',
    },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
