<div class="wrapper">
  <!-- Sidebar  -->
  <!-- Nav Bar -->
  
  <!-- <app-navbar *ngIf="currentUser && isnav"></app-navbar> -->
  <!-- Page Content  -->
  <div id="content">
    <!-- Top Bar -->
    <!-- <app-topbar *ngIf="currentUser && isnav"></app-topbar> -->
    
    <router-outlet></router-outlet>
  </div>
</div>
<!-- <ittf-loading-spinner></ittf-loading-spinner> -->
<!-- <app-toast></app-toast> -->