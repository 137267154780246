import { environment } from '../../../environments/environment';

let apiUrl = environment.apiUrl;
console.log("Get item from url constant");
console.log("localStorage",localStorage.getItem('apiUrl'));
if (localStorage.getItem('apiUrl') != undefined) {


console.log("localStorage local",localStorage.getItem('apiUrl'));
  apiUrl = localStorage.getItem('apiUrl');

console.log("localStorage updated url ",apiUrl);
}

export class AppUrlConstants {
  // Example
  static LOGIN_URL = apiUrl + '/user';
  static IMAGE_URL = 'assets/flag/';
  static GET_ROLES_URL = apiUrl + '/api/v1/common/roles';
  static GET_PERMISSIONS_URL = apiUrl + '/api/v1/common/permissions';
}

export class LoginUrlConstants {
  // Example
  static LOGIN_URL = apiUrl + '/api/v1/user';
  static CHANGE_PASSWORD_URL = apiUrl + '/api/v1/user';
  static SWITCH_DISCIPLINE_URL = apiUrl + '/api/v1/user/SwitchDiscipline';
  static MATCHHUB_URL = '/matchhub/umpires/landing.html';
  static VIDEOOVERLAY_URL = '/livesession/tables';
static LOCALFEED_URL = '/localfeeds';
}






export class MatchHubURLConstants {

    static Get_Match_Data =
    apiUrl + '/api/v1/matchhub/GetMatchData';

    static Get_Match_List_By_UmpireId =
    apiUrl + '/api/v1/matchhub/GetMatchesByUmpireId';

    static  GetMatchList =
    apiUrl + '/api/v1/matchhub/GetMatchList';

    static  GetMatchData =
    apiUrl + '/api/v1/matchhub/GetMatchData';

    static Get_Sessions =
    apiUrl + '/api/v1/matchhub/GetSessions';

    static UpdateDB =
    apiUrl + '/api/v1/matchhub/updateDB';

    static UpdateServeData =
    apiUrl + '/api/v1/matchhub/updateServeData';

    static DB_Unit_Action = 
    apiUrl + "/api/v1/MatchHub/updateUnitAction";

    static suspendMatch = 
    apiUrl + "/api/v1/MatchHub/Suspend";

    static authenticateRefereeLogin =
    apiUrl + "/api/v1/MatchHub/AuthenticateReferry";

}




