import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

export enum AppTheme {
    ITTF = 'ittf',
    WTT = 'wtt'
};

@Injectable()
export class ThemeProviderService {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    private themeBaseDirectory = "/assets/css/";
    private styleElementClassName = "app-branding-stylesheet";

    activeTheme: AppTheme = AppTheme.ITTF;

    private getStyleElement () {
        return this.document.head.querySelector(`link[rel="stylesheet"].${this.styleElementClassName}`);
    }

    setTheme(key: AppTheme) {
        this.activeTheme = key;
        this.getStyleElement().setAttribute('href', `${this.themeBaseDirectory}theme_${key}.css`);
    }

    toggleTheme() {
        this.setTheme(this.activeTheme === AppTheme.ITTF ? AppTheme.WTT : AppTheme.ITTF);
    }

    createThemeStyleElement () {
        let styleElement = this.document.head.querySelector(`link[rel="stylesheet"].${this.styleElementClassName}`);
        if (!styleElement) {
            styleElement = this.document.createElement('link');
            styleElement.className = this.styleElementClassName;
            styleElement.setAttribute('rel', 'stylesheet');
            styleElement.setAttribute('href', `${this.themeBaseDirectory}theme_wtt.css`);
            this.document.head.appendChild(styleElement);
        }
    }
}