import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
// import { ToastService } from '@app/shared/components/toast/toast.service';
import { LoaderService } from '@app/shared/components/loading-spinner/loader.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    // private toastService: ToastService,
    private loaderService: LoaderService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err, xx) => {
        if (err.status === 422) {
          if (err.error?.Message) {
            var errorMessage = err.error.Message;
            // this.toastService.raiseError({
            //   title: 'Error!',
            //   content: errorMessage,
            // });
          }
        }
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.loaderService.setLoader(false);
          this.router.navigate(['/logout']);
        }

        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}
