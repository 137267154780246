import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserService } from './services/user.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { CommonService } from './services/common.service';
import { LogoutComponent } from './components/logout/logout.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '../core';
import { LoaderService } from './components/loading-spinner/loader.service';
import { ThemeProviderService } from './services/theme-provider.service';
import { TranslateModule } from '@ngx-translate/core';


var modules: any[] = [
  CommonModule,
  FormsModule,
  RouterModule,
  ReactiveFormsModule,
  CoreModule
];

var declarations: any[] = [
  LogoutComponent
];

@NgModule({
  declarations: [declarations],
  imports: [modules, TranslateModule.forChild({ extend: true })],
  exports: [modules, declarations, TranslateModule],
  providers: [UserService, CommonService,
     ThemeProviderService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    
  ],
}
)
export class SharedModule {
  static forRoot() {
  return {
    ngModule: SharedModule,
    providers: [ LoaderService ]
  }
}}
