import { Injectable } from '@angular/core';
import { Timezone } from '../models/timezone.model';
import { Language } from '../models/description.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { AuthModel } from '@app/login/models/login.model';
// import { MomentDateFormat } from '../constants/constants';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from '@app/core/services/authentication.service';
@Injectable()
export class CommonService {
  public appTitle: string = 'OVR - MatchHub';
  public appTimeZone: string;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private titleService: Title
  ) {}



  language: Language[] = [{ value: 'ENG', text: 'ENG' }];

  descriptionCodes: string[] = ['LDESC', 'SDESC', null];

  incidences: string[] = ['OK', 'RET', 'INJ', 'DSQ', 'WO'];

  /**
   * To set the title of SPA document
   */
  public setTitle(newTitle: string) {
    const { eventId } = this.authService.currentUserValue;
    this.titleService.setTitle(`${this.appTitle} - ${newTitle} (${eventId})`);
  }

  getHttpParams(mapParams: Map<string, string>): HttpParams {
    let params = new HttpParams();
    for (let param of mapParams.entries()) {
      if (param[1] && param[1] !== null) {
        params = params.append(param[0], param[1]);
      }
    }
    return params;
  }


  getAppTimeZoneOffset(): number {
    const m = moment.tz(this.appTimeZone);
    const serverTimeZoneDiff = m.utcOffset() / 60;
    const clientSideTimeZoneDiff = new Date().getTimezoneOffset() / 60;
    const timezoneOffset = serverTimeZoneDiff + clientSideTimeZoneDiff;
    return this.appTimeZone ? timezoneOffset : null;
  }

  convertToAppTimeZone(
    date: string | Date,
    appTimeZone: string,
    isTimeReq?: boolean
  ): string {
    const clientTimeStartDate = moment(date).toString(); // *example : "Thu Oct 25 2018 04:30:00 GMT+0530"
    const serverTimeStartDate = moment.tz(new Date(), appTimeZone).toString(); // *example : "Thu Oct 25 2018 04:30:00 GMT+0530"
    let arrayClientStartDate = clientTimeStartDate.split(' ');
    const arrayServerStartDate = serverTimeStartDate.split(' ');

    arrayClientStartDate[arrayClientStartDate.length - 1] =
      arrayServerStartDate[arrayServerStartDate.length - 1];
    if (isTimeReq === true) {
      arrayClientStartDate[arrayClientStartDate.length - 2] = '00:00:00';
    }
    return arrayClientStartDate.join(' ');
  }

  public getFullTime(startTime: string, startDate: Date): Date {
    const startTimeArray = startTime.split(':');
    const newStartDate = new Date(startDate);
    return new Date(
      newStartDate.setHours(
        parseInt(startTimeArray[0]),
        parseInt(startTimeArray[1]),
        0,
        0
      )
    );
  }

}
