import { Component } from '@angular/core';
import { User } from './shared/models/user';
import { AuthModel } from './login/models/login.model';
import { AuthenticationService } from './core/services/authentication.service';


import {
  Router,Event,
  RouterEvent,
  NavigationStart,
  ActivatedRoute
} from '@angular/router';
import { LoaderService } from './shared/components/loading-spinner/loader.service';
import { Broadcaster } from './shared/models/broadcaster';
import { TranslateService } from '@ngx-translate/core';
import { ThemeProviderService } from './shared/services/theme-provider.service';
import { filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './shared/services/config.service';

// declare var $: any;
// declare var NiceCountryInput: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [Broadcaster],
})
export class AppComponent {
  title = 'MatchHub';
  //showspinner = false;

  currentUser: AuthModel;
  language: string;
  
  constructor(
    loaderService: LoaderService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private themeProviderService: ThemeProviderService,public router: Router,
    private configService: ConfigService
  ) {
    // router.canceledNavigationResolution = 'computed';
    this.configService.getConfig().subscribe((config) => {
      console.log("console.log(config.apiUrl);");
      console.log(config.apiUrl);
      localStorage.setItem('apiUrl', config.apiUrl);
    });
    this.themeProviderService.createThemeStyleElement();
    loaderService.setLoader(false);
    this.initializeApp();
    let user = JSON.parse(localStorage.getItem('currentUser'));
    this.routerinit()
    this.authenticationService.currentUser.subscribe(
      (x) => (
        (this.currentUser = x),
        (this.language = user?.language == null ? 'en' : user.language)
      )
    );
    window.addEventListener("beforeunload", (e) => {
      // Display a confirmation dialog when the page is being reloaded
      const confirmationMessage = "Are you sure you want to leave this page?";
      e.returnValue = confirmationMessage; // For older browsers
    
      // Use window.confirm() to show the dialog
      if (!window.confirm(confirmationMessage)) {
        // If the user clicks "Cancel" in the dialog, prevent the reload
        e.preventDefault();
      }  
      // Return the confirmation message (this may not work in all browsers)
      return confirmationMessage;
    });

    window.onpopstate = function(event) {    
      if(event && event.state) {
          location.reload(); 
      }
    }

    
    // this.initializeTranslator();
  }
  isnav=true
  routerinit(){
    this.router.events.pipe(
      filter((e: Event): e is RouterEvent => e instanceof NavigationStart )
   ).subscribe((e) => {
     // Do something
     console.log("watchrouter",e)
     let str = e.url.slice(0, -3);
     let strs = e.url.slice(0,-20)
     console.log('watchrouter',str)
    //  if(e.url=="/livesession/tables" || str == '/livesession/matchscore?tablename='){
    //    this.isnav=false
    //  }
     
    //  if(e.url=="/modality/presentationgroups" || strs == '/modality/presentationgroups?eventKey=' ){
     
    //   this.isnav=false
    // }
   });
  }

  initializeTranslator() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.addLangs(['en', 'zh']);
    this.translate.setDefaultLang('en');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(this.language);
  }

  initializeApp() {

  }
}
